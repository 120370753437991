<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px;max-height: 85%;">
    <div class="relative bg-white">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
          {{toolMasterId > 0 ? 'Update Tool' : 'Add New Tool'}}
        </div>
        <div v-if="toolMasterId > 0">
          <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="removeConfirm = true"> 
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <div class="p-4">
         <div class="mb-4">
              <TextField
              :inputId="'34324testInput'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="saveToolDetail.toolName"
              :placholderText="`Tool Name`"
              :lableText="'Tool Name'"
              :autoFocus="false"
              @inputChangeAction="(data) => saveToolDetail.toolName = data"  />

              <TextAreaInput
              :inputId="'435testInput'"
              :inputext="saveToolDetail.toolDescription"
              :placholderText="`Write Description here...`"
              :lableText="'Tool Description'"
              :inputType="'text'"
              :autoFocus="false"
              :textMaxlength="1000"
              :showcharLimit="true"
              :cols="50"
              :rows="3"
              :setReadOnly="false"
              @inputChangeAction="(data) => saveToolDetail.toolDescription = data" 
              />
          </div>
        </div>
      </div>
      <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
        <div class="text-rigth flex gap-2 mb-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" :disabled="saveToolDetail.toolName.trim() !== '' ? false : true" @buttonAction="SaveToolsDetail()"/>
        </div>
      </div>
    </div>
  </div>
  <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure delete tool?'" />
</div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['toolMasterId', 'toolDetail'],
components: {
  Button,
  TextField,
  TextAreaInput,
  ConfirmBox,
},
data () {
  return {
    removeConfirm: false,
    saveToolDetail: {
      toolMasterId:0,
      toolName:'',
      toolDescription:'',
      displayOrder:0,
      isActive:true,
      organizationDetailId:0
    }
  }
},
 created() {
},
mounted () {
  this.$root.$on('confirmBoxHandler', (response) => {
    document.body.style = 'overflow: visible;'
    if (response) {
        this.deleteApi()
      }
    this.removeConfirm = false
  })
  if (this.toolMasterId > 0) {
    if (this.toolDetail !== null) {
      this.saveToolDetail.toolMasterId = this.toolDetail.toolMasterId
      this.saveToolDetail.toolName = this.toolDetail.toolName
      this.saveToolDetail.toolDescription = this.toolDetail.toolDescription
      this.saveToolDetail.displayOrder = this.toolDetail.displayOrder
      this.saveToolDetail.isActive = this.toolDetail.isActive
      this.saveToolDetail.organizationDetailId = this.toolDetail.organizationDetailId
    }
  }
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  discard () {
      document.body.style = 'overflow: visible;'
    this.$root.$emit('addNewToolComponennt')
  },
  SaveToolsDetail () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.SaveToolsData(
      this.saveToolDetail,
      response => {
        this.$root.$emit('addNewToolComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  deleteApi () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DeleteToolDetail(
      this.toolMasterId,
      response => {
        console.log('response', response);
        this.$root.$emit('addNewToolComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  }
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
}
}
</script>
<style scoped>

</style>