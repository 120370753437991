<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Tools ({{toolsList.length}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Tool'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="toolsList.length > 0">
          <div v-for="(data, index) in toolsList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.toolMasterId, data)">
            <div class="flex items-center justify-between">
              <p class="text-gray4 heading-5" style="font-weight: bold;">{{data.toolName}}
              </p>
            </div>
            <div class="flex items-center justify-between" v-if="data.toolDescription">
              <p class="text-gray4 pt-1 heading-6" style="white-space: break-spaces;">{{data.toolDescription}}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>
      </div>
    </div>
    <add-edit-tax v-if="addTool" :toolMasterId="selectedCatId" :toolDetail="selectedDetail" />
   </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import AddEditTax from './addEditTools.vue'
export default {
  components: {
    NoRecords,
    Button,
    AddEditTax
  },
  data() {
    return {
      toolsList: [],
      searchForLead: '',
      addTool: false,
      totalCount: 0,
      selectedCatId: 0,
      selectedDetail: {}
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: visible;'
    this.getToolList()
    this.$root.$on("addNewToolComponennt", (response) => {
      console.log(response);
      if (response) {
        this.getToolList()
        this.addTool = false
      }
      this.addTool = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addNewToolComponennt')
  },
  methods: {
    openDetail (toolMasterId, data) {
      this.selectedCatId = toolMasterId
      this.selectedDetail = data
      this.addTool= true
    },
    addNewCust () {
      this.selectedCatId = 0
      this.selectedDetail = {}
      this.addTool= true
    },
    getToolList () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetToolsList(
          response => {
            this.toolsList = response.Data !== null ? response.Data : []
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  },
};
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>